import ReactComponent from '../app/ReactComponent'
import {Space, Layout, Row, Col} from 'antd'
import {Menu} from '../components/layout/Menu'
import {Link} from 'react-router-dom'
import {getQueryParameter} from '../helpers/global'
import {ReactComponent as Logo} from '../image/logo-desktop.svg'

export class Header extends ReactComponent {
  state = {
    hideMenu: false
  }

  async componentDidMount() {
    void this.updateState({
      hideMenu: (getQueryParameter("isHideMenu") || "").toLowerCase() === "true"
    })
  }

  render() {
    return (
      <Layout tagName="header" className={`header ${this.state.hideMenu && "hidden"}`}>
        <Row align="center">
          <Col xs={24}>
            <Space size={[0, 0]} style={{display: "flex"}}>
              <div style={{width: 32, height: 1}}></div>
              <Link to="/" className="logo">
                <Logo/>
              </Link>
              {this.props.$auth.is_authorized ? <Menu $auth={this.props.$auth}/> :
                <div style={{width: 32, height: 1}}></div>}
            </Space>
          </Col>
        </Row>
      </Layout>
    )
  }
}