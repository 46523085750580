export const AMBER_PLASMA = [
  {link: "proc-darovat/",text: "Proč darovat plazmu", id: "1" },
  {link: "stante-se-darcem/",text: "Staňte se dárcem", id: "2" },
  {link: "system-odmen/",text: "Odměny", id: "3" },
  {link: "novinky/",text: "Novinky", id: "4" },
  {link: "slovnik-pojmu/",text: "Slovník pojmů", id: "5"},
  {link: "faq/",text: "FAQ", id: "6" },
]

export const ABOUT_US = [
  {link: "o-nas/#o-spolecnosti",text: "O společnosti", id: "1" },
  {link: "kariera/",text: "Kariéra", id: "2"  },
  {link: "napsali-o-nas/",text: "Napsali o nás", id: "3" },
  {link: "partneri/",text: "Partneři", id: "4" },
  {link: "kontakty/",text: "Kontakty", id: "5" },
  {link: "o-nas-whistleblowing/",text: "Whistleblowing", id: "6" },
  {link: "gdpr/",text: "GDPR", id: "7" },
]

export const DONATION_CENTRES_FIRST = [
  {link: "darcovska-centra-ceska-lipa/",text: "Česká Lípa", id: "1" },
  {link: "darcovska-centra-decin/",text: "Děčín", id: "2" },
  {link: "darcovska-centra-cheb/",text: "Cheb", id: "3" },
  {link: "darcovska-centra-chomutov/",text: "Chomutov", id: "4" },
  {link: "darcovska-centra-jablonec-nad-nisou/",text: "Jablonec nad Nisou", id: "5" },
  {link: "darcovska-centra-karlovy-vary/",text: "Karlovy Vary", id: "6" },
]

export const DONATION_CENTRES_SECOND = [
  {link: "darcovska-centra-litomerice/",text: "Litoměřice", id: "7" },
  {link: "darcovska-centra-mlada-boleslav/",text: "Mladá Boleslav", id: "8" },
  {link: "darcovska-centra-plzen/",text: "Plzeň", id: "9" },
  {link: "darcovska-centra-praha-nove-butovice/",text: "Praha - Nové Butovice", id: "10" },
  {link: "darcovska-centra-pribram/",text: "Příbram", id: "11" },
  {link: "darcovska-centra-teplice/",text: "Teplice", id: "12" },

]