import React from 'react'
import {Link as LinkRouter} from 'react-router-dom'
import {Typography, Layout, Space, Row, Col} from 'antd'
import {FaFacebookF, FaInstagram} from 'react-icons/fa'
import {FooterItems} from './FooterItem'
import {AMBER_PLASMA, ABOUT_US, DONATION_CENTRES_FIRST, DONATION_CENTRES_SECOND} from '../services/footer'

import {ReactComponent as Logo}from '../image/logo-desktop-footer.svg'
import {PhoneOutlined} from '@ant-design/icons'
import {BiLogoLinkedin} from 'react-icons/bi'

const {Text, Link} = Typography

const AMBERPLASMAURL = "https://www.amberplasma.com/"

export class Footer extends React.Component {
  render() {
    return (
      <Layout tagName={"footer"}>
        <Row justify="center" className="footer-row first-row">
          <Col xs={24} md={24} lg={12} xl={16}>
            <Row>
              <Col xs={24} sm={12} md={6} lg={12} xl={6} className="footer-col-first">
                <Link href={AMBERPLASMAURL} className="footer-h3">Amber Plasma</Link>
                {AMBER_PLASMA.map(item =>
                  (
                    <div className="footer-item" key={item.id}>
                      <FooterItems link={AMBERPLASMAURL + item.link} text={item.text}/>
                    </div>
                  )
                )}
              </Col>
              <Col xs={24} sm={12} md={6} lg={12} xl={6} className="footer-col-second">
                <Link href={AMBERPLASMAURL + "o-nas/"} className="footer-h3">O nás</Link>
                {ABOUT_US.map(item =>
                  (
                    <div className="footer-item" key={item.id}>
                      <FooterItems link={AMBERPLASMAURL + item.link} text={item.text}/>
                    </div>
                  )
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={24} xl={12} className="footer-col-three">
                <Link href={AMBERPLASMAURL + "darcovska-centra/"} className="footer-h3">Dárcovská centra</Link>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {DONATION_CENTRES_FIRST.map(item =>
                      (
                        <div className="footer-item" key={item.id}>
                          <FooterItems link={AMBERPLASMAURL + item.link} text={item.text}/>
                        </div>
                      )
                    )}
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} className="footer-col-three-second">
                    {DONATION_CENTRES_SECOND.map(item =>
                      (
                        <div className="footer-item" key={item.id}>
                          <FooterItems link={AMBERPLASMAURL + item.link} text={item.text}/>
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} className="footer-col-four">
            <Space direction="vertical" className="footer-contact">
              <Typography.Title level={3} className="footer-h3">
                Bezplatná linka
              </Typography.Title>
              <Link className="footer-tel" href="tel:+420800606806">
                <PhoneOutlined rotate={90}/>
                <strong>
                  800 606 806
                </strong>
                <span>
									po–pá 7.30-16.00
								</span>
              </Link>
              <Text className="footer-text">Pro objednání či přeobjednání termínu, prosím volejte naší zelenou linku.
                Rezervovat termín si můžete i online na našem <LinkRouter to="/">rezervačním
                  portálu</LinkRouter>
              </Text>
              <LinkRouter to="/" className="btn-reservation">Objednat se</LinkRouter>
            </Space>
          </Col>
        </Row>
        <Row justify="center" className="footer-row second-row">
          <Col xs={0} md={8}></Col>
          <Col xs={24} md={8} className="footer-col-logo">
            <Space direction="vertical">
              <Link href={AMBERPLASMAURL} className="logo">
                <Logo/>
              </Link>
              <Text className="footer-copyright">© Amber Plasma a.s.</Text>
              {/*<Text style={{color:'#7899b9'}}>Created by <Link href="https://www.cortex.cz/" style={{color:'#7899b9'}}>Cortex, a.s.</Link></Text>*/}
            </Space>
          </Col>
          <Col xs={24} md={8} className="footer-social-link">
            <Space>
              <Link href="https://www.facebook.com/AmberPlasma/" className="social-link">
                <FaFacebookF/>
              </Link>
              <Link href="https://www.instagram.com/amberplasma/?hl=cs" className="instagram social-link">
                <FaInstagram/>
              </Link>
              <Link href="https://www.linkedin.com/in/amber-plasma-a-s-06a185280/" className="social-link">
                <BiLogoLinkedin/>
              </Link>
            </Space>
          </Col>
        </Row>
      </Layout>
    )
  }
}